import React, { useState, useEffect } from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import { Divider } from '@mui/joy';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/joy/styles';

import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            50: '#f0f7ff',
            100: '#c2e0ff',
            200: '#99ccff',
            300: '#66b2ff',
            400: '#3399ff',
            500: '#007fff',
            600: '#0072e5',
            700: '#0059b2',
            800: '#004c99',
            900: '#003a75',
          },
        },
      },
    },
    components: {
      JoyTable: {
        styleOverrides: {
          root: {
            '& th': {
              backgroundColor: 'var(--joy-palette-primary-100)',
              color: 'var(--joy-palette-primary-800)',
              fontWeight: 'bold',
            },
            '& td, & th': {
              padding: '12px',
              borderBottom: '1px solid var(--joy-palette-divider)',
            },
          },
        },
      },
      JoyTabList: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--joy-palette-background-level1)',
            borderRight: '1px solid var(--joy-palette-divider)',
          },
        },
      },
      JoyTab: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'var(--joy-palette-primary-50)',
            },
            '&.Mui-selected': {
              backgroundColor: 'var(--joy-palette-primary-100)',
              color: 'var(--joy-palette-primary-700)',
            },
          },
        },
      },
    },
  });

function TableStripeConditions({ conditionsDict }: { conditionsDict: Record<string, any> }) {
    return (
        <Sheet>
            <Link href={conditionsDict.url} level="title-md" target="_blank" rel="noopener noreferrer">
                Link to guideline
            </Link>
            <Table aria-label="striped table">
                <thead>
                    <tr>
                        <th>Clinical Signs and Symptoms</th>
                        <th>Guideline Directed Therapy</th>
                        <th>Goals of therapy/Efficacy endpoints</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ verticalAlign: 'top' }}>
                        <td>{conditionsDict["Clinical Signs and Symptoms"]}</td>
                        <td>{conditionsDict["Guideline Directed Therapy"]}</td>
                        <td>{conditionsDict["Goals of therapy/Efficacy endpoints"]}</td>
                    </tr>
                </tbody>
            </Table>
        </Sheet>
    );
}

function TableStripe({ drugDict }: { drugDict: Record<string, any> }) {
    return (
        <Sheet>
            <Link
                href={drugDict.url}
                level="title-md"
                target="_blank"
                rel="noopener noreferrer"
            >
                Link to monograph
            </Link>
            <Table aria-label="striped table">
                <thead>
                    <tr>
                        <th>Indications</th>
                        <th>Dosing Range</th>
                        <th>Side effects and Adverse drug reactions</th>
                        <th>Drug Interactions</th>
                        <th>Contraindications, Warnings and Precautions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ verticalAlign: 'top' }}>
                        <td>{drugDict.Indications}</td>
                        <td>{drugDict["Dosing Range"]}</td>
                        <td>{drugDict["Side effects/Adverse drug reactions"]}</td>
                        <td>{drugDict["Drug Interactions"]}</td>
                        <td>{drugDict["Contraindications and Warnings/Precautions"]}</td>
                    </tr>
                </tbody>
            </Table>
        </Sheet>
    );
}
interface EvidenceTableProps {
    data: {
        drugs: Record<string, any>;
        conditions: Record<string, any>;
    };
}

function EvidenceTable({ data }: EvidenceTableProps) {
    const { drugs, conditions } = data;

    return (
      <StyledEngineProvider injectFirst>
        <CssVarsProvider defaultMode="light" theme={theme}>
            <CssBaseline />
            <Box sx={{ p: 4 }}>
                <Typography fontSize={'2rem'} sx={{ mb: 3 }}>Clinical Evidence Table</Typography>
                <div style={{ whiteSpace: 'pre-line' }}>
                    <Tabs defaultValue={0} orientation="vertical">
                        <TabList>
                            <Typography level="title-md" sx={{ mt: 1, mb: 1, p: 1 }}>Medications</Typography>
                            {Object.keys(drugs).map((drug, index) => (
                                <Tab key={drug} value={index}>
                                    {drug}
                                </Tab>
                            ))}
                            <Divider />
                            <Typography level="title-md" sx={{ mt: 1.5, mb: 1, p: 1 }}>Conditions</Typography>
                            {Object.keys(conditions).map((condition, index) => (
                                <Tab key={condition + 99} value={index + 99}>
                                    {condition}
                                </Tab>
                            ))}
                        </TabList>
                        {Object.entries(drugs).map(([drug, drugData], index) => (
                            <TabPanel key={drug} value={index} sx={{ height: '75vh' }}>
                                <TableStripe drugDict={drugData as Record<string, any>} />
                            </TabPanel>
                        ))}
                        {Object.entries(conditions).map(([condition, conditionsData], index) => (
                            <TabPanel key={condition} value={index + 99} sx={{ height: '75vh' }}>
                                <TableStripeConditions conditionsDict={conditionsData as Record<string, any>} />
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </Box>
        </CssVarsProvider>
        </StyledEngineProvider>
    );
}

export default EvidenceTable;