import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/joy/Button';
import Badge from '@mui/joy/Badge';
import Chip from '@mui/joy/Chip';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import EvidenceTable from './EvidencePage';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';

const TitleIcon = React.memo(({ type }: { type: 'arrow' | 'loading' | 'check' | 'error' | 'help' | 'next' | 'addPt' }) => {
  const memoizedCircularProgress = useMemo(() => <CircularProgress thickness={4} size={15} />, []);
  switch (type) {
    case 'arrow':
      return <ArrowOutwardIcon />;
    case 'loading':
      return memoizedCircularProgress;
    case 'check':
      return <CheckCircleOutlineIcon color="success" />;
    case 'error':
      return <ErrorOutlineIcon color="error" />;
    case 'help':
      return <HelpOutlineIcon color="warning" />;
    case 'next':
      return <ArrowOutwardIcon />;
    case 'addPt':
      return <PersonAddAlt1Icon />;
    default:
      return null;
  }
});

export default function ProgressList({ handlePatientProfileButtonClick, drugsEvidence, conditionsEvidence, isDone, sectionHighlightCount, isPatientSelected, expandedSections, setExpandedSections, taskId, documentationLoading, documentationComplete, openDtpsTrue }: { handlePatientProfileButtonClick: () => void, drugsEvidence: any, conditionsEvidence: any, isDone: boolean, sectionHighlightCount: any, isPatientSelected: boolean, expandedSections: { [key: string]: boolean }, setExpandedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>, taskId: string, documentationLoading: boolean, documentationComplete: boolean, openDtpsTrue: () => void }) {
  const [analysisStatus, setAnalysisStatus] = useState<'arrow' | 'loading' | 'check' | 'error' | 'help' | 'next'>('next');

  useEffect(() => {
    if (Object.keys(drugsEvidence).length === 0) {
      setAnalysisStatus('next');
    } else if (!isDone && analysisStatus !== 'loading') {
      setAnalysisStatus('loading');
    } else {
      setAnalysisStatus('check');
    }
  }, [drugsEvidence, isDone]);

  const getProgressTrackerChip = () => {
    if (!isPatientSelected) {
      return <Chip size="sm" variant='solid' color='primary' startDecorator={<CreateIcon />} >Creating Patient Profile</Chip>;
    } else if (Object.keys(drugsEvidence).length === 0) {
      return <Chip size="sm" variant='solid' color='neutral' startDecorator={<SearchIcon />} >Gathering Evidence</Chip>;
    } else if (!isDone) {
      return <Chip size="sm" variant='solid' color='success' startDecorator={<CreateIcon />} >Generating Analysis</Chip>;
    }
    return <Chip size="sm" variant='solid' color='primary' startDecorator={<CheckIcon />} sx={{ fontSize: '0.70rem !important', height: '20px !important' }}>Workup Complete</Chip>;
  }

  const handleSectionClick = (section: string, subsection: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: true,
      [`${section}-${subsection}`]: !prev[`${section}-${subsection}`]
    }));
  };

  const getListItemButton = (text: string, section: string, subsection: string) => {
    const button = (
      <ListItemButton onClick={() => handleSectionClick(section, subsection)} disabled={Object.keys(drugsEvidence).length == 0}>{text}</ListItemButton>
    );
    return button;
  }

  const getDocumentationListItemButton = (text: string, section: string, subsection: string) => {
    const button = (
      <ListItemButton onClick={() => handleSectionClick(section, subsection)} disabled={!isDone}>{text}</ListItemButton>
    );
    return button;
  }
  const handleEvidenceClick = () => {
    window.open(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/utilities/evidence-tables/${taskId}/html`, '_blank');
  }
  ;

  return (
    <Box
      sx={{
        width: 320,
        pl: '24px',
        mt: 3.5,
      }}
    >
      <Typography fontSize={'2rem'}>
        Progress {getProgressTrackerChip()}
      </Typography>

      <List
        size="sm"
        sx={(theme) => ({
          '--joy-palette-primary-plainColor': '#8a4baf',
          '--joy-palette-neutral-plainHoverBg': 'transparent',
          '--joy-palette-neutral-plainActiveBg': 'transparent',
          '--joy-palette-primary-plainHoverBg': 'transparent',
          '--joy-palette-primary-plainActiveBg': 'transparent',
          [theme.getColorSchemeSelector('dark')]: {
            '--joy-palette-text-secondary': '#635e69',
            '--joy-palette-primary-plainColor': '#d48cff',
          },

          '--List-insetStart': '32px',
          '--ListItem-paddingY': '0px',
          '--ListItem-paddingRight': '16px',
          '--ListItem-paddingLeft': '21px',
          '--ListItem-startActionWidth': '0px',
          '--ListItem-startActionTranslateX': '-50%',

          [`& .${listItemButtonClasses.root}`]: {
            borderLeftColor: 'divider',
          },
          [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
            borderLeftColor: 'currentColor',
          },
          '& [class*="startAction"]': {
            color: 'var(--joy-palette-text-tertiary)',
          },
          pt: 1,
        })}
      >
        <ListItem nested>
          <ListItem component="div" startAction={<TitleIcon type={Object.keys(drugsEvidence).length > 0 ? 'check' : isPatientSelected ? 'loading' : 'next'} />}>
            <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>
              Research
            </Typography>
          </ListItem>
          <List sx={{ '--List-gap': '0px' }}>
            <ListItem>
              <Button
                fullWidth
                variant="soft"
                color="success"
                disabled={Object.keys(drugsEvidence).length === 0}
                onClick={handleEvidenceClick}
                sx={{
                  justifyContent: 'flex-start',
                  fontWeight: 'normal',
                }}
              >
                Clinical Evidence<LaunchIcon sx={{ fontSize: 'small', ml: .5 }} />
              </Button>
            </ListItem>
          </List>
        </ListItem>

        <ListItem nested>
          <ListItem component="div" startAction={<TitleIcon type={Object.keys(drugsEvidence).length === 0 ? 'next' : !isDone ? 'loading' : 'check'} />}>
            <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>
              Assessment
            </Typography>
          </ListItem>
          <List sx={{ '--List-gap': '0px' }}>
            <ListItem>
              {getListItemButton('Clinical Analysis', 'analysis', 'analysis')}
            </ListItem>
          </List>
        </ListItem>

        <ListItem nested>
          <ListItem component="div" startAction={<TitleIcon type={analysisStatus} />}>
            <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>
              Findings
            </Typography>
          </ListItem>
          <List sx={{ '--List-gap': '0px' }}>
            <ListItem>
              <ListItemButton 
                disabled={Object.keys(drugsEvidence).length === 0}
                onClick={openDtpsTrue}
              >
                Potential Drug Therapy Problems
              </ListItemButton>
            </ListItem>
          </List>
        </ListItem>

        <ListItem nested>
          <ListItem component="div" startAction={<TitleIcon type={documentationLoading ? 'loading' : documentationComplete ? 'check' : 'next'} />}>
            <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>
              Documentation
            </Typography>
          </ListItem>
          <List sx={{ '--List-gap': '0px' }}>
            <ListItem>
              {getDocumentationListItemButton('Clinical Summary', 'clinical summary', 'clinicalSummary')}
            </ListItem>
            <ListItem>
              {getDocumentationListItemButton('Patient Education', 'patient education', 'patientEducationSummary')}
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Box>
  );
}