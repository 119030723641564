// src/firebase/firebaseClient.ts

import { getApps, initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth } from "firebase/auth/web-extension"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const clientCredentials = {
    apiKey: "AIzaSyDM_EvKJ_F5mcN_2k6nQBNT-6IZtg0GHyw",
    authDomain: "asepha-firebase.firebaseapp.com",
    databaseURL: "https://asepha-firebase-default-rtdb.firebaseio.com",
    projectId: "asepha-firebase",
    storageBucket: "asepha-firebase.appspot.com",
    messagingSenderId: "114127988395",
    appId: "1:114127988395:web:e1f02747645e32322339ee",
    measurementId: "G-GZWNF6MZMY"
};

let firebase_app

// Check if firebase app is already initialized to avoid creating new app on hot-reloads
if (!getApps().length) {
    firebase_app = initializeApp(clientCredentials)
} else {
    firebase_app = getApps()[0]
}

export const storage = getStorage(firebase_app)
export const auth = getAuth(firebase_app)
export const db = getFirestore(firebase_app)
export const googleAuth = new GoogleAuthProvider()

export default firebase_app