 
import {
  indexedDBLocalPersistence,
  onAuthStateChanged,
  setPersistence
} from "firebase/auth/web-extension"

import React, { useState } from "react"
 
import { auth } from "./firebaseClient"

setPersistence(auth, indexedDBLocalPersistence)
 
export default function useFirebaseUser() {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<any | null>(null)
 
  const onLogout = async () => {
    setIsLoading(true)
    if (user) {
      await auth.signOut()
  }
}
 
  const onLogin = () => {
    if (!user) return
 
    const uid = user.uid
    // Get current user auth token
    user.getIdToken(true).then(async (token: string) => {
      // Send token to background to save
     
    })
  }
 
  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLoading(false)
      setUser(user)
    })
  }, [])
 
  React.useEffect(() => {
    if (user) {
      onLogin()
    }
  }, [user])
 
  return {
    isLoading,
    user,
    onLogin,
    onLogout
  }
}

