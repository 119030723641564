import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import { Box } from '@mui/joy';

import { useNavigate } from 'react-router-dom';

export default function EndSection() {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '80vh' // Optional, ensures full viewport height
                }}
            >
                <Card
                    sx={{
                        textAlign: 'center',
                        alignItems: 'center',
                        '--icon-size': '100px',
                        width: 'fit-content', // Adjust based on content
                    }}
                    style={{ whiteSpace: 'pre-line', maxWidth: '45vw' }}
                >
                    <CardOverflow>
                        <AspectRatio
                            variant="outlined"
                            ratio="1"
                            sx={{
                                m: 'auto',
                                transform: 'translateY(50%)',
                                borderRadius: '50%',
                                width: 'var(--icon-size)',
                                boxShadow: 'sm',
                                bgcolor: 'background.surface',
                                position: 'relative',
                            }}
                        >
                            <div>
                                <HealthAndSafetyIcon sx={{ fontSize: '4rem' }} />
                            </div>
                        </AspectRatio>
                    </CardOverflow>
                    <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 2)' }}>
                        Thank You
                    </Typography>
                    <CardContent sx={{ maxWidth: '40ch' }} style={{ whiteSpace: 'pre-line' }}>
                        During medication administration, there is an 8%-25% error rate.<br /> Thank you for working with us to reduce this rate to 0%!
                    </CardContent>
                    <CardActions
                        orientation="vertical"
                        buttonFlex={1}
                        sx={{
                            '--Button-radius': '40px',
                            width: '40ch',
                            alignContent: 'center',
                        }}
                    >
                        <FormControl sx={{ p: 4 }}>
                            <FormLabel>How would you rate Asepha's performance?</FormLabel>
                            <Textarea
                                placeholder="Type in here…"
                                minRows={2}
                            />
                            <FormHelperText>Your feedback helps us improve our performance!</FormHelperText>
                        </FormControl>
                        <Button variant="solid" sx={{ alignSelf: 'center', minWidth: '30%', backgroundColor: '#8ab586' }}>
                            Submit
                        </Button>

                    </CardActions>
                </Card>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Center the button horizontally
                }}
            >
                <Button onClick={() => window.location.replace("/assessment")} size="md" sx={{ backgroundColor: '#458795', color: 'white' }}>
                    Start New Workup
                </Button>
            </Box>
        </Box>
    );
}
